// @ts-ignore
if (!('localStorage' in window) || !window.localStorage || !window.localStorage.setItem) {
	(window as any).localStorage = {
		_data: {},
		setItem: function (id: string, val: any) {
			return (this._data[id] = val);
		},
		getItem: function (id: any) {
			return this._data[id];
		},
		removeItem: function (id: any) {
			return delete this._data[id];
		},
		clear: function () {
			return (this._data = {});
		},
	};
}
